import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Papa from "papaparse";
import { TbError404 } from "react-icons/tb";
import OrientedImagery from "./gql/OrientedImageryQuery";

const OrientedImageryButton = ({ projectName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [orientedImagery] = useLazyQuery(OrientedImagery);

  const download = async () => {
    setIsLoading(true);
    try {
      const oI = await orientedImagery({
        variables: { projectName: projectName },
      });

      if (oI?.error) {
        setIsError(true);
      }

      const fields = [
        "X",
        "Y",
        "Z",
        "SRS",
        "ImagePath",
        "Name",
        "AcquisitionDate",
        "CameraHeading",
        "OrientedImageryType",
      ];

      const config = {
        quotes: false,
        quoteChar: '"',
        escapeChar: ",",
        delimeter: ",",
        dynamicTyping: true,
        worker: true,
        header: true,
        newLine: "\r\n",
        skipEmptyLines: true,
      };

      const csv = Papa.unparse(
        { fields: fields, data: oI.data.orientedPhotos.nodes },
        config
      );

      const csvForDownload = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });

      const csvUrl = window.URL.createObjectURL(csvForDownload);

      const testLink = document.createElement("a");
      testLink.href = csvUrl;
      testLink.download = `${projectName}-OITable.csv`;
      testLink.click();
      setIsLoading(false);
    } catch (error) {
      console.error();
      setIsLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  return (
    <Button
      size="sm"
      style={{ color: "white", width: "150px" }}
      onClick={() => download()}
    >
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <TbError404 />
      ) : (
        "Download Oriented Imagery"
      )}
    </Button>
  );
};

export default OrientedImageryButton;
