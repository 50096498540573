import { gql } from "@apollo/client";

const UploadSubscription = gql`
  subscription UploadSubscription($topic: String!, $userId: UUID!) {
    listen(topic: $topic) {
      query {
        allUploads(condition: { userId: $userId }, orderBy: UPLOADED_DESC) {
          nodes {
            projectName
            imageCount
            id
            status
            uploaded
            zipFileName
            zipFileSize
            imageCount
            isBulk
            failedFilesByUploadId {
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default UploadSubscription;
