import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ExifReader from "exifreader";
import { format, parse } from "date-fns";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import has from "lodash/has";
import get from "lodash/get";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import UploadsMutation from "./gql/UploadsMutation";
import SingleUploadMutation from "./gql/SingleUploadMutation";
import PhotoUploadMutation from "./gql/PhotoUploadMutation";
import UploadConfirmMutation from "./gql/UploadConfirmMutation";
import UpdatePhotoGeom from "./gql/UpdatePhotoGeomMutation";
import FailedUploadMutation from "./gql/FailedUploadMutation";
import ProjectSelect from "./ProjectSelect";
import DeviceSelect from "./DeviceSelect";

const dateTimeFormatter = (date, time, dateTime, dateTimeFormat) => {
  let outDate = date;

  if (dateTime) {
    const getDate = parse(dateTime, dateTimeFormat, new Date());
    outDate = format(getDate, "yyyy-MM-dd HH:mm:ss");
    //console.info(outDate);
    return outDate;
  }
  // if there is no date, return null

  if (date && time) {
    const dateTime = date.toString() + " " + time.toString();
    const checkDate = parse(dateTime, dateTimeFormat, new Date());
    outDate = format(checkDate, "yyyy-MM-dd HH:mm:ss");
    return outDate;
  }

  return null;
};

const UploadForm = ({ user }) => {
  const [projectName, setProjectName] = useState();
  const [deviceName, setDeviceName] = useState();

  //device fields
  const [latField, setLatField] = useState();
  const [lonField, setLonField] = useState();
  const [altField, setAltField] = useState();
  const [headField, setHeadField] = useState();
  const [imageryType, setImageryType] = useState();
  const [dateTimeField, setDateTimeField] = useState();
  const [dateField, setDateField] = useState();
  const [timeField, setTimeField] = useState();
  const [dateTimeFormat, setDateTimeFormat] = useState();
  const [fileFormat, setFileFormat] = useState();

  //file upload properties
  const [files, setFiles] = useState([]);
  const [numFiles, setNumFiles] = useState(0);
  const [currentFile, setCurrentFile] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [newUpload] = useMutation(UploadsMutation);
  const [singleUpload] = useMutation(SingleUploadMutation);
  const [uploadPhoto] = useMutation(PhotoUploadMutation);
  const [confirmUpload] = useMutation(UploadConfirmMutation);
  const [updateGeom] = useMutation(UpdatePhotoGeom);
  const [fileError] = useMutation(FailedUploadMutation);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let startUpload;
    try {
      setUploading(true);
      startUpload = await newUpload({
        variables: {
          userId: user.appId,
          projectName: projectName,
          zipFileName: "N/A",
          zipFileSize: 0,
          bulk: false,
        },
      });

      if (!startUpload.data.createUpload.upload.id) {
        throw new Error("Missing Upload id");
      }
      var fileCount = 0;
      var totalSize = 0;
      setNumFiles(files.length);
      for (let i = 0; i < files.length; i++) {
        setCurrentFile(i);

        if (files[i].type === fileFormat) {
          const baseName = files[i].name.split("\\").pop().split("/").pop();

          const fileExt = baseName.substr(baseName.lastIndexOf(".") + 1);

          const webName =
            Date.now().toString(36) +
            Math.floor(
              Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
            ).toString(36) +
            "." +
            fileExt;

          const keyName = `uploads/${encodeURI(projectName)}/${
            startUpload.data.createUpload.upload.id
          }/${webName}`;

          const uploadInfo = await singleUpload({
            variables: {
              uploadId: startUpload.data.createUpload.upload.id.toString(),
              projectName: projectName,
              fileName: webName,
            },
          });

          const buffer = await files[i].arrayBuffer();
          const tags = ExifReader.load(buffer, {
            expanded: true,
            includeUnknown: true,
          });

          if (!tags) {
            console.error("Missing EXIF Data");
            await fileError({
              variables: {
                uploadId: startUpload.data.createUpload.upload.id.toString(),
                filename: baseName,
                problem: "Missing EXIF Data",
                relativePath: files[i].webkitRelativePath,
              },
            });
            continue;
          }
          // check if file has GPS Information
          if (!has(tags, latField) || !has(tags, lonField)) {
            console.error("Missing Geotagging Data");
            await fileError({
              variables: {
                uploadId: startUpload.data.createUpload.upload.id.toString(),
                filename: baseName,
                problem: "Missing Geotagging Data",
                relativePath: files[i].webkitRelativePath,
              },
            });
            continue;
          }

          if (altField) {
            if (!has(tags, altField)) {
              console.error("Missing Altitude Field");
              await fileError({
                variables: {
                  uploadId: startUpload.data.createUpload.upload.id.toString(),
                  filename: baseName,
                  problem: "Oriented Imagery Missing Altitude Data",
                  relativePath: files[i].webkitRelativePath,
                },
              });
              continue;
            }
          }

          if (headField) {
            if (!has(tags, headField)) {
              console.error("Missing Heading Field");
              await fileError({
                variables: {
                  uploadId: startUpload.data.createUpload.upload.id.toString(),
                  filename: baseName,
                  problem: "Oriented Imagery Missing Heading Data",
                  relativePath: files[i].webkitRelativePath,
                },
              });
              continue;
            }
          }

          if (
            (!has(tags, timeField) || !has(tags, dateField)) &&
            !has(tags, dateTimeField)
          ) {
            console.error("Missing Date Time");
            await fileError({
              variables: {
                uploadId: startUpload.data.createUpload.upload.id.toString(),
                filename: baseName,
                problem: "Missing Date Time",
                relativePath: files[i].webkitRelativePath,
              },
            });
            continue;
          }

          const photoDateTime = dateTimeFormatter(
            get(tags, dateField) || null,
            get(tags, timeField) || null,
            get(tags, dateTimeField),
            dateTimeFormat
          );

          if (
            !photoDateTime ||
            !startUpload.data.createUpload.upload.uploaded
          ) {
            console.error(
              "Problems with the format of the file or exif time stamps"
            );
            await fileError({
              variables: {
                uploadId: startUpload.data.createUpload.upload.id.toString(),
                filename: baseName,
                problem:
                  "Problems with the format of the file or exif time stamps",
                relativePath: files[i].webkitRelativePath,
              },
            });
            continue;
          }

          let gpsLatitude = get(tags, latField);
          let gpsLongitude = get(tags, lonField);
          let gpsAltitude = get(tags, altField) || null;
          let gpsHeading = get(tags, headField) || null;

          gpsLatitude = parseFloat(gpsLatitude.toFixed(6));
          gpsLongitude = parseFloat(gpsLongitude.toFixed(6));
          gpsAltitude = gpsAltitude
            ? parseFloat(gpsAltitude.toFixed(6))
            : gpsAltitude;
          gpsHeading = gpsHeading ? parseFloat(gpsHeading) : gpsHeading;

          const url = `${process.env.REACT_APP_IMAGE_SITE}/${keyName}`;

          let success = false;
          while (!success) {
            try {
              await fetch(uploadInfo.data.singleUpload.url, {
                method: "PUT",
                headers: {
                  "Content-Type": files[i].type,
                  "Cache-Control": "public",
                  "Content-Length": files[i].size,
                },
                body: files[i],
              });
              success = true;
            } catch (error) {
              success = false;
            }
          }

          await uploadPhoto({
            variables: {
              uploadId: startUpload.data.createUpload.upload.id.toString(),
              url: url,
              lat: gpsLatitude,
              lon: gpsLongitude,
              alt: gpsAltitude,
              head: gpsHeading,
              imgType: imageryType,
              originalFileName: baseName,
              photoDateTime: photoDateTime,
              projectName: projectName,
              webname: webName,
            },
          });
        }
        totalSize += files[i].size;
        fileCount += 1;
        continue;
      }

      setUploading(false);

      setUploadError(false);
      e.target.reset();

      await updateGeom({
        variables: {
          uploadId: startUpload.data.createUpload.upload.id.toString(),
        },
      });

      await confirmUpload({
        variables: {
          id: startUpload.data.createUpload.upload.id.toString(),
          count: fileCount.toString(),
          size: totalSize,
          status: "COMPLETE",
        },
      });

      setProjectName();
      setDeviceName();
      setLatField();
      setLonField();
      setAltField();
      setHeadField();
      setDateField();
      setTimeField();
      setDateTimeField();
      setDateTimeFormat();
      setImageryType();
      setFileFormat();
      setFiles([]);
    } catch (error) {
      await confirmUpload({
        variables: {
          id: startUpload.data.createUpload.upload.id.toString(),
          count: fileCount.toString(),
          size: totalSize,
          status: "ERROR",
        },
      });

      setUploading(false);
      setUploadError(false);
      e.target.reset();
      setUploadError(true);
      console.error(error);
      setProjectName();
      setDeviceName();
      setLatField();
      setLonField();
      setAltField();
      setHeadField();
      setDateField();
      setTimeField();
      setDateTimeField();
      setDateTimeFormat();
      setImageryType();
      setFileFormat();
      setFiles([]);
    }
  };

  return (
    <>
      <Modal show={uploading} centered>
        <Modal.Header>
          <Modal.Title>Uploading {files?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          File {currentFile} of {numFiles}
          <ProgressBar
            now={numFiles > 0 && Math.floor((currentFile / numFiles) * 100)}
            label={
              numFiles > 0 && `${Math.floor((currentFile / numFiles) * 100)}%`
            }
          ></ProgressBar>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Card>
        <Card.Header>
          <Card.Title>Upload New Files</Card.Title>
        </Card.Header>
        <Card.Body>
          {uploadError && <Alert>There was an error with the upload.</Alert>}
          <Form id="upload-form" onSubmit={handleSubmit}>
            <Row className="p-2">
              <ProjectSelect
                projectName={projectName}
                setProjectName={setProjectName}
              />
            </Row>
            <Row className="p-2">
              <DeviceSelect
                deviceName={deviceName}
                setDeviceName={setDeviceName}
                setDateField={setDateField}
                setDateTimeField={setDateTimeField}
                setTimeField={setTimeField}
                setLatField={setLatField}
                setLonField={setLonField}
                setAltField={setAltField}
                setHeadField={setHeadField}
                setImageryType={setImageryType}
                setDateTimeFormat={setDateTimeFormat}
                setFileFormat={setFileFormat}
              />
            </Row>
            <Row className="p-2">
              <Form.Group>
                <Form.Label>Select Folder to Upload</Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    multiple
                    webkitdirectory=""
                    mozdirectory=""
                    directory=""
                    type="file"
                    onChange={(e) => setFiles(e.target.files)}
                  />

                  <Button
                    size="sm"
                    disabled={uploading || files.length < 1}
                    type="submit"
                    form="upload-form"
                    style={{ color: "white" }}
                  >
                    Upload
                  </Button>
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UploadForm;
