import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/esm/Container";
import Spinner from "react-bootstrap/esm/Spinner";
import Alert from "react-bootstrap/Alert";
import UploadSubscription from "./gql/UploadSubscription";
import UploadsQuery from "./gql/UploadsQuery";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import * as Dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { RiFileDamageFill } from "react-icons/ri";
import FormatBytes from "../Administration/Uploads/utils/FormatBytes";

Dayjs.extend(utc);

const Uploads = ({
  user,
  setProjectName,
  setShowBrokenFile,
  setUploadId,
  brokenFile,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);

  const { data, loading, error, subscribeToMore } = useQuery(UploadsQuery, {
    variables: { userId: user.appId },
  });

  const MAX_PAGES_DISPLAYED = 5;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(data?.allUploads?.nodes?.length / itemsPerPage);

  subscribeToMore({
    document: UploadSubscription,
    variables: { topic: `upload_update:${user?.appId}`, userId: user?.appId },
    updateQuery: (output) => {
      return output?.allUploads;
    },
  });

  const handlePaginationClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(
      1,
      activePage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_DISPLAYED - 1);
    if (startPage > 1) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-start"
          onClick={() => handlePaginationClick(startPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          title={`Page ${i}`}
          key={i}
          active={i === activePage}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => handlePaginationClick(endPage + 1)}
        />
      );
    }

    return items;
  };

  if (loading) {
    return (
      <Container>
        <Spinner
          variant="primary"
          animation="grow"
          style={{ width: "400", height: "400" }}
        />
      </Container>
    );
  }

  if (error) {
    return <Alert>There was an error retrieving uploads.</Alert>;
  }

  if (data) {
    return (
      <>
        <Card>
          <Card.Header>
            <Card.Title>Uploads</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive="md" size="sm" striped hover>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Status</th>
                  <th>Date Uploaded</th>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Count</th>
                  <th>Failed</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.allUploads?.nodes
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((upld, idx) => (
                    <tr key={idx}>
                      <td>{upld.projectName}</td>
                      <td>
                        {upld.status === "COMPLETE" ||
                        upld.state === "ERROR" ? (
                          upld.status
                        ) : (
                          <>
                            <Spinner size="sm" variant="primary" />{" "}
                            {upld.status}
                          </>
                        )}
                      </td>
                      <td>
                        {Dayjs(upld.uploaded)
                          .utc(true)
                          .local()
                          .format("M/DD/YYYY h:mm A")}
                      </td>
                      <td>{upld.zipFileName}</td>
                      <td>{FormatBytes(upld.zipFileSize)}</td>
                      <td>
                        {upld.imageCount > 0 ? upld.imageCount : "Preparing"}
                      </td>
                      <td>{upld.failedFilesByUploadId.totalCount}</td>
                      <td>
                        <InputGroup>
                          <Button
                            title="Show Failed Uploads"
                            disabled={
                              upld.status !== "COMPLETE" ||
                              upld.failedFilesByUploadId.totalCount < 1
                            }
                            size="sm"
                            variant="warning"
                            style={{ color: "white" }}
                            onClick={() => {
                              setProjectName(upld.projectName);
                              setShowBrokenFile(() => !brokenFile);
                              setUploadId(upld.id);
                            }}
                          >
                            <RiFileDamageFill />
                          </Button>
                        </InputGroup>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th
                    colSpan={10}
                    rowSpan={1}
                    style={{
                      fontWeight: "normal",
                      paddingTop: 0,
                      paddingBottom: 0,
                      margin: 0,
                    }}
                  >
                    <Pagination
                      size="sm"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <Form.Select
                        size="sm"
                        title={`Show ${itemsPerPage} per page`}
                        style={{
                          maxWidth: "140px",
                          borderRight: 0,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value={10}>Items/Page</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>
                      <Pagination.First
                        title="Goto First Page"
                        onClick={() => handlePaginationClick(1)}
                      />
                      <Pagination.Prev
                        title="Goto Prev Page"
                        onClick={() =>
                          handlePaginationClick(
                            activePage > 1 ? activePage - 1 : 1
                          )
                        }
                      />
                      {renderPaginationItems()}
                      <Pagination.Next
                        title="Goto Next Page"
                        onClick={() =>
                          handlePaginationClick(
                            activePage < totalPages
                              ? activePage + 1
                              : totalPages
                          )
                        }
                      />
                      <Pagination.Last
                        title="Goto Last Page"
                        onClick={() => handlePaginationClick(totalPages)}
                      />
                    </Pagination>
                  </th>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          {/* <Card.Footer>
                        <Pages
              currentPage={page}
              setCurrentPage={setPage}
              maxPages={maxPages}
            />
          </Card.Footer> */}
        </Card>
      </>
    );
  }
};

export default Uploads;
