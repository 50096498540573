import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Status from "./Status";
//import User from "./User";

const Header = ({ user }) => {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <img
              alt="Thompson Consulting Service Logo"
              src="/logo192.png"
              width="35"
              height="35"
            />{" "}
          </Link>
        </Navbar.Brand>
        <Navbar.Brand>TCS PhotoLog</Navbar.Brand>
        <Navbar.Collapse></Navbar.Collapse>
        {/* <Nav tabIndex={0}>
          <User user={user} />
        </Nav> */}
        <Nav className="me-auto d-flex align-items-middle">
          <Nav.Link
            style={{ color: "white" }}
            as={Link}
            to="/"
            title="Upload Files"
          >
            Upload
          </Nav.Link>
          <Nav.Link
            style={{ color: "white" }}
            as={Link}
            to="/gis"
            title="GIS Service URLs"
          >
            GIS Services
          </Nav.Link>
          {user?.isAdmin && (
            <Nav.Link
              style={{ color: "white" }}
              as={Link}
              to="/admin"
              title="Administration"
            >
              Admin
            </Nav.Link>
          )}
          <Navbar.Text style={{ color: "white" }}>| {user?.email}</Navbar.Text>
          <Nav.Link
            style={{ color: "white" }}
            as={Link}
            to="/login"
            title={`Logout ${user?.email}`}
          >
            | Logout
          </Nav.Link>
          <Navbar.Text>
            <Status />
          </Navbar.Text>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
